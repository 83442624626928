<!-- IndustryTrendsDetail -->
<template>
    <div class="IndustryTrendsDetail">
        <el-page-header @back="$router.go(-1)" content="产品交流帖子"></el-page-header>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item>产品交流</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!-- boxShadow -->
        <div class="postInfo">
            <div class="title">
                {{ postInfo.postHead }}
            </div>
            <div class="flex subtitle">
                <div class="flex">
                    <img class="img" src="@/assets/Community/IndustryTrends/date.png" alt="">
                    <div class="subtitle">
                    </div>
                    {{ postInfo.createDate }}
                </div>
            </div>
            <div class="postContent">
                <div v-html="postInfo.postContent" style="line-height: 28px;">
                </div>
                <div class="imgList f-w-b">
                    <div class="item mb-30" v-for="(item, index) in postInfo.mobjectImgList" :key="index">
                        <img class="img" :src="item" alt="">
                    </div>
                </div>
            </div>
        </div>

        <div class="comment">
            <div class="text">评论</div>
            <div class="vueEmoji">
                <textarea class="textarea" v-model="commentValue" placeholder="善语结善缘，恶语伤人心~">
                </textarea>
                <img @click="addForumPostComment()" class="commentBtn"
                    src="@/assets/Community/IndustryTrends/commentBtn.png" alt="">
            </div>
            
            <div class="text mt-60">全部评论（{{ commentList.length }}）</div>
            <div class="comment-box">
                <CommentItems :DetailInfo="postInfo" @forumNewsPostCommentList="forumPostCommentList" :list="commentList"
                    :CommentType="'first'" :showCheck="true" queryType="ProductExchange">
                </CommentItems>
            </div>
            <Pagination v-if="commentList.length" :total="total" :pageNum.sync="currentPage" :limit.sync="size"
                :layout="layout" @pagination="fetchData" />
            <el-empty v-if="commentList.length == 0" description="暂无评论！"></el-empty>
        </div>

    </div>
</template>
  
<script>

// import VueEmoji from 'emoji-vue';
import { PaginationRetention1 } from '@/mixins/PaginationRetentionMixin';

export default {
    name: 'IndustryTrendsDetail',
    mixins: [PaginationRetention1],
    components: {
        CommentItems: () => import("../components/CommentItems.vue"),
        // SecondaryComment: () => import("../components/SecondaryComment.vue")
    },
    data() {
        return {
            postInfo: {},
            postId: '',
            commentValue: '',
            commentList: [],
            ShowSecondaryCommentsDialog: false,//显示二级评论盒子
            SecondaryParams: {},
        }
    },
    created() {
        let postId = JSON.parse(this.$UrlEncode.decode(this.$route.query.key)).postId;
        this.postId = postId;
        this.forumArticleDetails(postId);
        this.getDate();
        // this.findForumMediumState();
    },
    methods: {
        getDate() {
            this.forumPostCommentList(this.paging, this.pagingSize,);
        },
        forumArticleDetails(postId) {
            let that = this;
            that.$http.forumArticleDetails({
                postId,
                postUserId: parseInt(that.$store.state.userId),
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data,
                    { postContent } = res.data
                    records.postContent = postContent.replace(/\n/g, "<br/>");
                    records.postContent = records.postContent.replace(/ /g, "&nbsp;");
                    // &nbsp;
                    that.postInfo = records;
                    console.log(records)
                }
            })
        },
        addForumPostComment() {
            let that = this;
            if (that.commentValue) {
                let data = {
                    fcommentLocation: that.$getStorage('getIpAddress').fcommentLocation,
                    sourceIp: that.$getStorage('getIpAddress').sourceIp,
                    postId: that.postInfo.postId,
                    fcommentConent: that.commentValue,
                    mobject: "",
                    fcommentUserId: parseInt(that.$store.state.userId)
                }
                
                that.$http.addForumPostComment(data).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, 'success');
                        that.page = 1;
                        that.size = 10;
                        that.getDate();

                    } else {
                        that.$common.message(res.msg, 'error')
                    }
                })
            } else {
                that.$common.message('请输入评论！', 'error')
            }

        },
        // 
        forumPostCommentList(page = this.page, size = this.size) {
            let that = this;
            that.$http.forumPostCommentList({
                fcommentUserId: parseInt(that.$store.state.userId),
                postId: that.postId,
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach((item) => {
                        item.fcommentLocation = item.fcommentLocation.split('*')[0]
                        item.showCommonInput = false;
                        item.replyShow = false;
                    })
                    that.commentList = records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total
                }
            })
        },
        // 显示二级评论盒子
        showSecondCommontBox(data) {
            let that = this;
            that.SecondaryParams = data;
            that.ShowSecondaryCommentsDialog = true;
        },
        // 关闭二级评论盒子
        closeSecondaryCommentsDialog(data) {
            this.ShowSecondaryCommentsDialog = false;
            data ? this.forumPostCommentList() : ''
            // switch (this.queryType) {
            //     case 'ProductExchange':
            //         data ? this.forumPostCommentList() : ''
            //         break;
            //     case 'ShiLiaoZaTan':
            //         data ? this.forumChatPostCommentList() : ''
            //         break;
            // }
        },

        findForumMediumState(){
            let that = this;
            that.$http.findForumMediumState({
                userId: parseInt(that.$store.state.userId)
            }).then(res => {
                if (res.code == 200) {
                    console.log(res.data)
                }
            })
        }
    },
}

</script>
<style lang='less' scoped>
.IndustryTrendsDetail {
    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .postInfo {
        // margin-top: 60px;
        margin-left: 1px;
        margin-right: 1px;
        border-radius: 10px;
        padding: 40px 68px;

        .title {
            font-weight: 500;
            font-size: 28px;
            color: #000000;
            margin-bottom: 30px;
        }

        .subtitle {
            font-weight: 400;
            font-size: 14px;
            color: #666666;

            .img {
                margin-right: 5px;
            }
        }

        .postContent {
            margin-top: 40px;
            font-weight: 400;
            font-size: 16px;
            color: #333333;

            .imgList {
                // border: 1px solid red;
                margin-top: 20px;

                .item {

                    .img {
                        width: 542px;
                        height: 344px;
                        border-radius: 5px;
                    }
                }
            }
        }


    }

    .comment {
        padding: 40px 68px;
        .text {
            font-weight: 500;
            font-size: 20px;
            color: #333333;
            margin-bottom: 20px;
        }

        .vueEmoji {
            position: relative;

            .textarea {
                width: 1100px;
                height: 180px;
                resize: none;
                font-size: 18px;
                padding: 30px 20px 50px 30px;
                border-radius: 10px;
                border: 1px solid #dfdfdf;
            }

            .commentBtn {
                // position: relative;
                position: absolute;
                right: 140px;
                bottom: 20px;
                cursor: pointer;

            }
        }

        .comment-box {
            // margin-top: 300px;
            padding: 30px;
        }
    }

}
</style>
  